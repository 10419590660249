@import "../../styles/variables";

.group {
  flex: 1;
  flex-basis: calc(33.333% - 15px);
  max-width: calc(33.333% - 15px);
  min-height: 100px;
  border-radius: 3px;
  background-color: $hover-grey;
  margin: 15px 15px 0 0;
  padding: 15px 20px;
  position: relative;
  display: flex;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
  flex-direction: column;
  @include withBorder;
  @include rounded;

  &.isActive {
    background-color: $white;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    margin-top: 0;
  }

  & :global(.ui.message.red) {
    box-shadow: 0 0 0 1px #ffb5b5 inset, 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.head {
  padding-bottom: 20px;
  min-height: 82px;
}

.title {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: $font-grey;
  margin: 0 0 12px;
  line-height: 1.6;

  .isActive & {
    color: $font-grey;
  }
}

.amount {
  font-size: 27px;
  font-weight: bold;
  line-height: 0.94;
  color: $font-grey;
}

.currency {
  text-transform: uppercase;
  font-size: 17.3px;
  line-height: 1.45;
}

.actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
}

.action {
  margin-right: 0.4rem;
  transition: fill 250ms ease-in-out;

  svg {
    fill: transparentize($primary-color, 0.6);
    transition: fill 250ms ease-in-out;
    vertical-align: middle;

    .isActive & {
      color: #a8b0be;
    }
  }

  &:hover {
    svg {
      fill: $primary-color;
    }
  }
}

.subItem {
  background: $background;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0.2rem;
  padding: 7px 20px;
  transition: background 300ms ease;
  color: $font-grey;

  &:hover:not(.error) {
    background: $primary-light;
    transition: background 300ms ease;
  }

  &.disabled:not(.error) {
    background-color: transparentize(#f4f6f9, .42);
    color: #a3a3a3;
  }

  &.error {
    background-color: transparentize(#ff7d83, .42);
    cursor: default;

    svg {
      cursor: pointer;
    }
  }
}

.subItemDetail {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;

  svg {
    margin-right: 10px;
  }
}
.subItemAmount {
  text-align: right;
  font-size: 13.9px;
  font-weight: bold;
  color: $primary-color;
  margin-left: auto;

  .disabled & {
    color: #a3a3a3;
  }
}
.subItemCurrency {
  font-size: 11.3px;
  text-transform: uppercase;
}

.footer {
  text-align: center;
  margin-bottom: -15px;
}

.openBtn {
  display: block;

  svg {
    width: 2.5em;
    height: 2.5em;
    fill: $font-grey;
    transition: all .3s ease-in-out;
  }

  &:hover, &:focus {
    svg {
      transform: rotate(360deg);
    }
  }
}
