@import "../../../../styles/variables.scss";

.container {
  transition: height .5s ease-in-out;
  overflow: hidden;
  min-height: 100px;
  position: relative;
  flex: 1;
  margin: 5px;
  padding: 5px;
}

.loader {
  z-index: 1 !important;
}

.refreshButton {

  svg {
    transition: all .6s ease-in-out;
    fill: #98afd7;
  }

  &:hover {
    svg {
      transform: rotate(720deg);
    }
  }
}

.lastUpdate {
  font-size: 10px;
  padding: 0 10px 5px;
  color: #999
}

.refreshMsgDimmer:global(.ui.active.dimmer) {
  display: flex !important;
}

.refreshMsg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.refreshMsgTitle {
  color: #000000;
  font-weight: 600;
  font-size: 11px;
  margin: 0;
}

.refreshMsgActions {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-size: 11px;
    display: inline-block;
    padding: 5px 10px;
    color: #4c4c4c;
    text-decoration: underline;
  }

  :global(.ui.secondary.button) {
    height: 28px !important;
    text-transform: none;
    padding-left: 10px;
    padding-right: 10px;
  }
}
