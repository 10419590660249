.cardlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding-bottom: 30px;
}

.cardPopup {
    background-color: #ffffff;
    border-radius: 20px;
    padding-bottom: 30px;
}

.cardInfo {
    padding: 20px 15px;
}

.cardTitle {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

.buttonc {
    text-transform: none;
    background-color: #ffffff;
    border: none;
    font-size: 16px !important;
    width: 208px !important;
    height: 44px !important;
    justify-content: center !important;
    margin-right: 5px !important;
    border-radius: 24px !important;
    padding: 20px 24px !important;
    font-weight: bold !important;
}

.cardTotals {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;
    background: #f8f8f8 !important;
    font-family: "Muli", sans-serif !important;
}

.cardDetail {
    display: flex;
    gap: 10px;
}

.pMoney {
    color: #0170d4 !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.pMoney24 {
  color: #0170d4 !important;
  font-size: 40px !important;
  font-weight: bold !important;
}

.pTitle {
    font-size: 13px !important;
    color: #a3a3a3 !important;
    font-family: "Muli", sans-serif !important;
}

.dialogContentText{
    padding: 0 27px !important;
  }
  .dialogContent{
    padding: 0 0 31px !important;
    & div{
      justify-content: center;

    }
  }
  .dialog{
    min-height: 328px !important;
  }
  .MuiDialog-paperWidthSm {
    max-width: 1000px !important;
  }

  .titlePopup {
    display: flex;
    padding: 50px;
    justify-content: space-between;
    align-items: center;
  }
  .contentPopupFirstSection {
    display: flex;
    justify-content: space-between !important; 
    padding: 0px 50px;
  }

  .contentPopupFirstSectionMoney {
    font-size: 25px !important;
    color: #0046a2 !important;
    font-weight: bold !important;
    margin-top: 10px !important;
  }


  .subTitlePopup {
    padding: 50px 50px 0px 50px;
  }

  .cardlistPopup {
    display: flex;
    justify-content: space-between !important;
    padding: 0 50px;
  }
  .cardlistPopupTotals {
    display: flex;
    padding: 0 50px;
    justify-content: center !important;
    gap: 50px;
  }
  .cardColumnPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
  }
  .cardColumnPopup100 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    width: 100%;
  }
  .cardColumnPopup50 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    width: 50%;
  }
  .cardInfoTotalGeneral {
    border: 1px solid #9E9E9E !important;
    border-radius: 15px;
    padding: 20px;
    color: #7a7a7a !important;
  }
  .w50 {
    width: 50%;
  }
  .pointer {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }